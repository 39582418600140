<template>
  <div class="app-container">
    大会简介
  </div>
</template>

<script>
//
export default {
  name: 'Introduction'
}
</script>

<style scoped>

</style>
